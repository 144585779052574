<template>
    <v-card>
      <v-card-title>
        <v-icon
          small
          color="white"
          class="elevation-6 pink lighten-1 pa-2"
        >
          mdi-magnify
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col
                cols="4"
              >
                <v-text-field
                  v-model="form.ruleName"
                  :label="$vuetify.lang.t('$vuetify.feeRule.ruleName')"
                  clearable
                />
              </v-col>

              <v-col
                cols="4"
              >
                <v-select
                  v-model="form.ruleType"
                  :label="$vuetify.lang.t('$vuetify.feeRule.ruleType')"
                  :items="ruleTypeItems"
                  menu-props="auto"
                  item-text="name"
                  item-value="value"
                  clearable
                ></v-select>
              </v-col>

              <v-col
                cols="4"
              >
                <v-select
                  v-model="form.feeType"
                  :label="$vuetify.lang.t('$vuetify.feeRule.feeType')"
                  :items="feeTypeItems"
                  menu-props="auto"
                  item-text="name"
                  item-value="value"
                  clearable
                ></v-select>
              </v-col>

              <v-col
                cols="12"
              >
                <v-btn
                  class="mx-2 mt-2 float-right"
                  color="primary"
                  fab
                  dark
                  small
                  @click="handleSearch"
                >
                  <v-icon dark>
                    mdi-magnify
                  </v-icon>
                </v-btn>
              </v-col>

            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
</template>

<script>
  export default {
    data() {
      return {
        ruleTypeItems: [
          {
            name: this.$vuetify.lang.t('$vuetify.ruleType.standard'),
            value: 'STANDARD'
          },{
            name: this.$vuetify.lang.t('$vuetify.ruleType.expert'),
            value: 'EXPERT'
          }

        ],
        feeTypeItems: [
          {
            name: this.$vuetify.lang.t('$vuetify.serviceFrequency.seriesFrequency'),
            value: 'SERIES_FREQUENCY'
          },
          {
            name: this.$vuetify.lang.t('$vuetify.serviceFrequency.combinationPackage'),
            value: 'COMBINATION_PACKAGE'
          }
        ],
        form: {
          ruleName: null,
          ruleType: null,
          feeType: null
        }
      }
    },
    computed: {
    },
    created () {
    },
    methods: {
      handleSearch() {
        const data = {}
        for (let k in this.form) {
          if (this.form[k]) {
            data[k] = this.form[k]
          }
        }
        this.$emit('submit', data)
      }
    }
  }
</script>

<style lang="scss" scoped></style>
